<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                     <div class="columns">
                        <div class="column is-10">
                            <periodoHora
                            :dataInicial.sync="dataInicial"
                            :dataFinal.sync="dataFinal"
                            :placeHolder="$t('AGENDARELACAO.SELECIONEPERIODO')"
                            :selecionadas="[dataInicial,dataFinal]"
                            />
                        </div>
                        <div class="column is-1">
                            <button class="button is-info" type="button" @click="filtrar({dataInicial, dataFinal})">{{$t('AGENDARELACAO.FILTRAR')}}</button>
                        </div>
                        <div class="column is-1">
                            <button v-if="model.filter(x => x.criarGuia).length > 0" class="button is-primary" type="button" @click="criarGuia()">{{$t('AGENDARELACAO.CRIARGUIA')}}</button>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.FILTRARRESPONSAVEL')}}</b>
                            <b-select v-model="funcionarioIdFiltro" expanded>
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.funcionariosAtivosFiltro">
                                    <option v-if="funcionarioIdFiltro == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.FILTRARAGENDAMENTO')}}</b>
                            <b-select v-model="tipoAgendamentoIdFiltro" expanded>
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.tiposAgendamentoAtivosFiltro">
                                    <option v-if="tipoAgendamentoIdFiltro == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.FILTRARSTATUS')}}</b>
                            <b-select v-model="statusFiltro" expanded>
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.statusAgenda">
                                    <option v-if="statusFiltro == item.valor" selected v-bind:key="index" :value="item.valor">
                                        {{item.descricao}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.valor">
                                        {{item.descricao}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.BUSCAPACIENTE')}}</b>
                            <b-field>
                                <b-input :placeholder="$t('AGENDARELACAO.PROCURAR')"
                                        v-model="procurar"
                                        icon="magnify"
                                        @input="loadAsyncData"></b-input>
                            </b-field>
                        </div>
                    </div>

                     <br />

                    <b-table :data="model" striped hoverable>
                        <template>
                            <b-table-column v-slot="props" field="id" label="Id">
                                {{ props.row.id}}
                            </b-table-column>                            
                            <b-table-column v-slot="props" field="data" :label="$t('AGENDARELACAO.DATA')">
                                {{ props.row.dataHora | datahora}}
                            </b-table-column>
                            <b-table-column  v-slot="props" field="paciente" :label="$t('SISTEMA.PACIENTE')">
                                {{ props.row.pacienteNome}}
                            </b-table-column> 
                            <b-table-column v-slot="props" field="endereco" :label="$t('AGENDARELACAO.ENDERECO')">
                                {{ wordWrap(props.row.pacienteEndereco, 30)}}
                            </b-table-column>                               
                            <b-table-column  v-slot="props" field="bairro" :label="$t('AGENDARELACAO.BAIRRO')">
                                {{ props.row.pacienteBairro}}
                            </b-table-column>                               
                            <b-table-column  v-slot="props" field="cep" :label="$t('AGENDARELACAO.CEP')">
                                {{ props.row.pacienteCEP}}
                            </b-table-column>                               
                            <b-table-column  v-slot="props" field="cidade" :label="$t('AGENDARELACAO.CIDADE')">
                                {{ props.row.pacienteCidade}}
                            </b-table-column>                               
                            <b-table-column  v-slot="props" field="fone" :label="$t('AGENDARELACAO.FONE')">
                                {{ props.row.pacienteFone}}
                            </b-table-column>                               
                            <b-table-column  v-slot="props" field="Observacao" :label="$t('AGENDARELACAO.OBSERVACAO')">
                                {{ props.row.observacao}}
                            </b-table-column>
                            <b-table-column  v-slot="props" field="tipoagendamento" :label="$t('AGENDARELACAO.TIPOAGENDAMENTO')">
                                {{ props.row.tipoAgendamentoNome}}
                            </b-table-column>
                            <b-table-column  v-slot="props" field="funcionarioresponsavel" :label="$t('AGENDARELACAO.FUNCIONARIORESPONSAVEL')">
                                {{ props.row.funcionarioNome}}
                            </b-table-column>   
                            <b-table-column  v-slot="props" field="exames" :label="$t('AGENDARELACAO.EXAMES')">
                                <b-tag type="is-default" v-for="(exame,index) in props.row.examesApelidos" :key="index">{{ exame }}</b-tag>
                            </b-table-column>   
                            <b-table-column v-slot="props" field="log" :label="$t('AGENDARELACAO.HISTORICO')">                                
                                    <b-button type="is-info" native-type="button" icon-left="clock" 
                                    class="button-delete" @click="exibirHistorico(props.row)" ></b-button>                                
                            </b-table-column>
                            <b-table-column  v-slot="props" field="guia" :label="$t('AGENDARELACAO.ABRIRGUIA')" custom-key="actions" numeric>
                                <router-link v-if="props.row.guiaId" :to="{ name: 'guia', params: { id: props.row.guiaId }}" class="button is-normal is-link is-outlined" title="Editar">
                                    <b-icon icon="square-edit-outline" size="is-small"></b-icon>
                                </router-link>
                                <b-checkbox :disabled="props.row.naoPodeCriarGuia" 
                                @input="verificarPaciente(props.row)" 
                                :title="$t('AGENDARELACAO.CRIARGUIA')" 
                                v-else-if="props.row.pacienteId != null && props.row.convenioId != null && props.row.examesApelidos != null" 
                                v-model="props.row.criarGuia"></b-checkbox>
                            </b-table-column>
                        </template>
                    </b-table>
                </article>
            </div>
        </div>        
    </section>
</template>

<style lang="scss" scoped>

</style>

<script>
    import titlebar from '@/components/titlebar.vue'
    import periodoHora from '@/components/periodoHora.vue'
    import moment from 'moment';    
    import historicoAlteracoes from "@/components/historicoAlteracoes.vue";
    import debounce from 'lodash/debounce';

    export default {
        components: {
            titlebar,
            periodoHora            
        },
        computed: {
            titleStack() {
                return [
                    this.$t('AGENDARELACAO.ATENDIMENTO'),
                    this.$t('AGENDARELACAO.RELACAOAGENDAMENTO'),
                ]
            }
        },
        data() {
            return {
                dataInicial: moment().add('month',-1).set('hours',0).set('minutes',0).toDate(),
                dataFinal: moment().set('hours',23).set('minutes',59).toDate(),
                model: [],
                atendimentoInterno: true,
                coletaDomiciliar: true,
                item: {
                    tipoAgendamento: null,
                },
                funcionarioIdFiltro: null,
                funcionariosAtivosFiltro: [],
                tipoAgendamentoIdFiltro: null,
                statusFiltro: null,
                tiposAgendamentoAtivosFiltro: [],
                statusAgenda:[],
                isLoading: false,
                loadedData: false,
                todos: null,
                procurar: null,
            }
        },
        created(){
            try {
                if (this.$route.query.dataInicial) this.dataInicial = moment(this.$route.query.dataInicial,'DD/MM/YYYY HH:mm').toDate();
                if (this.$route.query.dataFinal) this.dataFinal = moment(this.$route.query.dataFinal,'DD/MM/YYYY HH:mm').toDate();            

                

                this.loadData();
            }catch(ex){
                console.error(ex);
            }
        },
        mounted() {
            this.$http.get('/api/search/FuncionariosAtivos')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.funcionariosAtivosFiltro = data;
                }
            });

            this.$http.get('/api/search/ListarStatusAgenda')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.statusAgenda = data;
                }
            });

            this.$http.get('/api/search/tiposagendamentos' + 
                (this.$route.query.unidadeId != null ? `?unidadeId=${this.$route.query.unidadeId}` : ''))
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.tiposAgendamentoAtivosFiltro = data;
                }
            });            
        },
        filters: {
            datahora:  (valor) => {
            if (!valor) return null;    
            
            return  moment(valor).format('DD/MM/YYYY HH:mm:ss');
            }
        },
        methods: {   
            verificarPaciente(item){
                if(item.criarGuia){
                    this.model.forEach(element => {
                        element.naoPodeCriarGuia = (item.pacienteId != element.pacienteId || item.convenioId != element.convenioId)
                    });
                }
                else if(this.model.filter(x => x.criarGuia).length == 0) {
                    this.model.forEach(element => {
                        element.naoPodeCriarGuia = false
                    });
                }
            },
            criarGuia(){
                const agendar = [];
                const examesIds = [];     

                this.model.filter(x => x.criarGuia).forEach(element => {
                    element.examesIds.forEach(exameId => {
                        examesIds.push(exameId);
                    })
                });
  
                agendar.push({
                    examesIds: examesIds,
                    evento: {
                        id: this.model.filter(x => x.criarGuia)[0].id
                    }
                });
                
                this.$router.push({ name: 'guia', params: { 
                    pacienteId: this.model.filter(x => x.criarGuia)[0].pacienteId, 
                    convenioIdAgendar: this.model.filter(x => x.criarGuia)[0].convenioId, 
                    agendar: agendar 
                }});
            },
            wordWrap(str, maxWidth) {
                if(str == null){
                    return "";
                }

                const newLineStr = "\n";
                let res = '';
                while (str.length > maxWidth) {                 
                    let found = false;
                    for (let i = maxWidth - 1; i >= 0; i--) {
                        if (this.testWhite(str.charAt(i))) {
                            res = res + [str.slice(0, i), newLineStr].join('');
                            str = str.slice(i + 1);
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        res += [str.slice(0, maxWidth), newLineStr].join('');
                        str = str.slice(maxWidth);
                    }

                }

                return res + str;
            },
            testWhite(x) {
                const white = new RegExp(/^\s$/);
                return white.test(x.charAt(0));
            },   
            exibirHistorico(item) {
                this.$buefy.modal.open({
                    parent: this,
                    component: historicoAlteracoes,
                    props: {
                        tabela: '',
                        chave: item.id,
                        toggle: true,
                        agendaLog: true,
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },            
            filtrar(datas) {
                this.dataInicial = datas.dataInicial;
                this.dataFinal = datas.dataFinal;
                this.loadData();
            },

            loadData(){
                const params = [];

                if (this.dataInicial != null) params.push(`dataInicial=${moment(this.dataInicial).format('YYYY-MM-DDTHH:mm:ss')}`);
                if (this.dataFinal != null) params.push(`dataFinal=${moment(this.dataFinal).format('YYYY-MM-DDTHH:mm:ss')}`);
                if (this.statusFiltro != null) params.push(`status=${this.statusFiltro}`);
                if (this.tipoAgendamentoIdFiltro != null) params.push(`tipoAgendamentoId=${this.tipoAgendamentoIdFiltro}`);
                if (this.funcionarioIdFiltro != null) params.push(`funcionarioId=${this.funcionarioIdFiltro}`);                
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`); 
                if (this.procurar != null) params.push(`paciente=${this.procurar}`);
                if (this.$route.params.pacienteId != null) params.push(`pacienteId=${this.$route.params.pacienteId}`)
                
                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/RelacaoAgenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.model = data?.map(d => {
                                    return {
                                        ...d,
                                        dataHora: moment(d.dataHora).toDate(),
                                        atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                                        atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                                    }
                                });
                            }
                            this.isLoading = false;
                            this.loadedData = true;
                        }).catch(e => {
                            throw e;
                        });
                }catch(e){
                    console.error(e);
                    this.isLoading = false;
                }

            },
            loadAsyncData: debounce(function () {
                this.loadData();
            }, 500),
           
        }
    }
</script>