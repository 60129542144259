<template>
    <div class="column is-3">
        <div :class="classes" @click.capture="selecionar">
            <div class="column is-5">
                <h3 class="size-3">
                    <a :title="guiaexame.item + ' - ' +  guiaexame.exameNome"
                       v-if="podeAbrirResultado"
                       :href="'/guia/resultado?guiaId=' + guia.id + '&item=' + guiaexame.item">
                        {{ guiaexame.exameApelido }}
                    </a>
                    <span :title="guiaexame.item + ' - ' +  guiaexame.exameNome" v-else>{{ guiaexame.exameApelido }}</span>
                    <router-link v-if="guiaexame.copiaDe"
                                 class="is-pulled-right"
                                 :title="$t('GUIAEXAME.RESULTADOVINCULADO') + guiaexame.copiaDe.item + $t('GUIAEXAME.GUIAVINCULADO') + guiaexame.copiaDe.guiaId"
                                 :to="{ name: 'guia', params: { id: guiaexame.copiaDe.guiaId }}">
                        <b-icon icon="link"></b-icon>
                    </router-link>
                    <b-icon v-if="guiaexame.incidencia"
                            icon="alert-rhombus"
                            size="is-medium"
                            class="is-pulled-right"
                            title="Exame com incidência"
                            type="is-warning"></b-icon>
                </h3>
                <div v-if="guiaexame.item == 0">
                    <div class="field">
                        <div class="control">
                            <input class="input is-small" type="text" maxlength="5" v-model="guiaexame.observacao">
                        </div>
                    </div>
                </div>
                <div v-else>
                    <b-tag v-if="guiaexame.observacao">{{ guiaexame.observacao }}</b-tag>
                </div>
            </div>
            <div class="column has-text-centered">
                <p class="is-size-7">{{ guiaexame.dataDeSolicitacao | moment('DD/MM/YY') }}</p>
                <p class="is-size-7">{{ guiaexame.dataDeSolicitacao | moment('HH:mm') }}</p>
            </div>
            <div v-if="guiaexame.item == 0" class="column is-flex">
                <button class="delete is-small is-pulled-right has-background-danger" :title="$t('GUIAEXAME.EXCLUIREXAME')" @click.stop="$emit('excluirExame', {index, guiaexame})"></button>
                <b-button :class="{ 'is-warning': guiaexame.urgente }"
                          icon-left="alert-circle"
                          size="is-small"
                          :title="$t('GUIAEXAME.LIGADESLIGAURGENCIA')"
                          @click="setUrgente"></b-button>
                <b-button :class="{ 'is-link': guiaexame.materialEntregue }"
                          v-if="!guiaexame.exameDeFaturamento  && !portal && !guiaexame.exameSetorExamesDeImagem && !guiaexame.exameDeOutrasDespesas && !guiaexame.exameDePerfil"
                          icon-left="water"
                          size="is-small"
                          :title="$t('GUIAEXAME.LIGADESLIGAMATERIAL')"
                          @click="setMaterialEntregue"></b-button>
                <b-button  v-if="guiaexame.requerAutorizacao"
                          icon-left="asterisk"
                          size="is-small"
                          :title="$t('GUIAEXAME.AUTORIZACAOCONVENIO')">
                          </b-button>                          
            </div>
            <div v-if="guiaexame.item > 0" class="column is-flex"> 
                <b-button :class="{ 'is-warning': guiaexame.urgente }"
                          icon-left="alert-circle"
                          size="is-small"
                          :title="$t('GUIAEXAME.LIGADESLIGAURGENCIA')"
                          @click="setUrgente"></b-button>
                <b-button  v-if="guiaexame.requerAutorizacao"
                          icon-left="asterisk"
                          size="is-small"
                          :title="$t('GUIAEXAME.AUTORIZACAOCONVENIO')">
                          </b-button>                            
            </div>
            <div v-if="guiaexame.item > 0 && ((!guiaexame.exameDeFaturamento && !guiaexame.exameDeOutrasDespesas && !guiaexame.exameDePerfil ) || guiaexame.cancelado)" class="column" :title="guiaexame.statusTitle">
                <div class="has-text-weight-bold has-text-centered" :style="guiaexame.statusHtmlStyle">
                    {{ guiaexame.statusSigla }}
                </div>
            </div>
            <div v-if="guiaexame.item > 0 && guiaexame.exameDeFaturamento" class="column" title="Exame de Faturamento">
                <div class="has-text-weight-bold has-text-centered">
                    F
                </div>
            </div>
            <div v-if="guiaexame.item > 0 && guiaexame.exameDeOutrasDespesas" class="column" title="Exame de Outras Despesas">
                <div class="has-text-weight-bold has-text-centered">
                    D
                </div>
            </div>
            <div v-if="guiaexame.item > 0 && guiaexame.exameDePerfil && !guiaexame.exameDeFaturamento" class="column" title="Exame de Perfil">
                <div class="has-text-weight-bold has-text-centered">
                    P
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            guiaexame: Object,
            guia: Object,
            index: Number,
            selecionado: Boolean,
            portal: Boolean,
        },
        data() {
            return {
                classes: this.defineClasses()
            };
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            podeAbrirResultado() {
                return !this.portal && 
                    this.guiaexame.materialEntregue && 
                    !this.guiaexame.cancelado && 
                    !this.guiaexame.bloqueado && 
                    this.guiaexame.item != 0 &&
                    this.isInRole('resultado-editar')
            },
        },
        watch: {
            selecionado: function () {
                this.classes = this.defineClasses()
            }
        },
        methods: {
            defineClasses() {
                return "columns is-multiline is-mobile guiaExame" +
                    (this.$props.selecionado ? " guiaExameSelecionado" : "")
            },
            setUrgente() {
                this.guiaexame.urgente = !this.guiaexame.urgente
                localStorage.caraterUrgencia = this.guiaexame.urgente
                this.$emit('loadPrecosExames')
            },
            setMaterialEntregue() {
                this.guiaexame.materialEntregue = !this.guiaexame.materialEntregue
                localStorage.materialEntregue = this.guiaexame.materialEntregue
            },
            selecionar() {
                if (this.guiaexame.item != 0) {
                    this.$emit('selecionarExame', this.guiaexame)
                }
            }
        }
    }
</script>